//
//
//
//
//
//
//
//
//
//

// import firebase from 'firebase'
// import 'firebase/firestore';
import { mapActions } from 'vuex';
import Cities from '@/modules/cities/components/import-excel/cities1.vue';
import Regions from '@/modules/cities/components/import-excel/regions1.vue';
// import Login from '@/modules/auth/components/login.vue';
export default {
  data(){
  
    return{
      fileName:'',
      loading:true,
      cities:[],
      regions:[],
      newRegions:[],
      citiesRegions:[],
      regionDialog:false,
      regionEn:'',
      regionAr:'',
      columns: [       
        { name: 'ID', align: 'center', label: 'ID', field: 'id',},
        { name: 'Region', align: 'center', label: 'Region', field: 'name',},
        { name: 'City', align: 'center', label: 'City', field: 'city',},
      ],
      pagination: {
            sortBy: 'desc',
            descending: false,
            page: 1,
            rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7
      },
    }
  },
  components: {
  
        [Cities.name]: Cities,
        [Regions.name]: Regions
    },
computed: {
    pagesNumber () {
      return Math.ceil(this.regions.length / this.pagination.rowsPerPage)
    }
  },
  methods: {
    ...mapActions({
      doCreate: 'citiesRegions/form/doCreate'
    }),
    doSubmit(){
     
      // let regionCity = {}
      let cityInRegion = {}
      
      this.regions.forEach((region)=>{
        this.cities.forEach(city => {
        if(city[0] == region[1]){
          cityInRegion = {
              en: city[2],
              ar: city[1]
          } 
        }
        })

        // region['name'] = this.regionCity
        // const regionName = 
        //   {
        //       en : this.regionEn,
        //       ar : this.regionAr
        //   }
        // region['regions'] = {
        //   name: regionName
        // }
        let regionToAdd = {
          name: cityInRegion,
          regions:{
            name:{
              ar: region[2],
              en: region[3]
            }
             
          }
         
        }
        this.newRegions.push(regionToAdd)
        // this.doCreate(regionToAdd)
        // const city =  this.cities.filter(city => city[0] === this.regions[1])
        // this.doCreate(region)
      })
      // this.doCreate(this.newRegions[0])
      this.newRegions.forEach(region => {
        this.doCreate(region)
      })
      
    },
    // onItemClick (id) {
      
    // },
    addRegion(){
      // this.doCreate(region)
    },
    getCities(cities){
      this.cities = cities
    },
    getRegions(regions){
      this.regions = regions
    }
  },
  async created(){
  //   await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true },async () => {
  //     this.cities = []
  //     const cities = await firebase.firestore().collection("city").get({source: 'cache'})
  //     cities.forEach(async (doc) => {
  //       let city = doc.data()
  //       city['id'] = doc.id
  //       city['regions'] = []
  //       let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
  //       regions.forEach(async (region) =>{
  //         let regionInCity = region.data()
  //         city['regions'].push(regionInCity)
  //         regionInCity['city'] = city.name
  //         this.regions.push(regionInCity)
  //       })
  //       this.cities.push(city)
        
  //     })
  
      
  //     this.loading = false
  //  })
  }
};
